import * as globalModels from "influencers-models";

const fetchCampaignsByCompanyId = `
query fetchCampaignsByCompanyId(
    $${globalModels.campaignFields.companyId}: ID!
){
  campaigns(
    ${globalModels.campaignFields.companyId}: $${globalModels.campaignFields.companyId},
    ${globalModels.campaignFields.active}: true,
    orderBy: "creationDt",
    asc: false,
  ){
    _id,
    name,
    brief,
    type,
    productPaymentDescription,
    customProductUsagePrice,
    paymentType,
    stock,
    customAdPrice
    creationDt,
    startDt,
    endDt,
    active,
    status,
    investment,
    budgetAvailable,
    budgetFreezed,
    budgetSpent,
    moneyPerEngagementExpected,
    moneyPerEngagementReal,
    engagementReal,
    engagementExpected,
    influencersExpected,
    influencersTotal,
    engagementVelocityReal,
    engagementVelocityExpected,
    forecastDays,
    tagCount,
    likeCount,
    sharedCount,
    linkCount,
    printCount,
    mentionCount,
    hashtagCount,
    commentCount,
    engagementNotSponsored,
    engagementMaleInfluencer,
    engagementFemaleInfluencer,
    engagementMaleInfluenced,
    engagementFemaleInfluenced,
    engagementAnonymousInfluenced,
    facebookAnonymousInfluencedCount,
    twitterAnonymousInfluencedCount,
    instagramAnonymousInfluencedCount,
    facebookLikeCount,
    facebookLinkCount,
    facebookTagCount,
    facebookSharedCount,
    facebookMentionCount,
    facebookHashtagCount,
    facebookPrintCount,
    facebookCommentCount,
    twitterLikeCount,
    twitterLinkCount,
    twitterTagCount,
    twitterSharedCount,
    twitterMentionCount,
    twitterHashtagCount,
    twitterPrintCount,
    twitterCommentCount,
    instagramLikeCount,
    instagramLinkCount,
    instagramTagCount,
    instagramSharedCount,
    instagramMentionCount,
    instagramHashtagCount,
    instagramPrintCount,
    instagramCommentCount,
    facebookMaleInfluencerCount,
    facebookFemaleInfluencerCount,
    facebookMaleInfluencedCount,
    facebookFemaleInfluencedCount,
    instagramMaleInfluencerCount,
    instagramFemaleInfluencerCount,
    instagramMaleInfluencedCount,
    instagramFemaleInfluencedCount
  }
}`;

export default {
    fetchCampaignsByCompanyId
};
